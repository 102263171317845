<template>
  <v-container class="fill-height d-flex justify-center">
    <div>
      <v-img :src="logoUrl" />
    </div>
  </v-container>
</template>

<script>
import { getAppLogoUrl } from '@/util/company-attributes';

export default {
  name: 'SupplierPortalIndex',

  data() {
    return {
      logoUrl: getAppLogoUrl(),
    };
  },
};
</script>

<style scoped></style>
